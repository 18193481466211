import styled from '@emotion/styled';
var tinycolor = require('tinycolor2');

export const PostDetails = styled.article(
  (props) => `
  ${
    props.theme.dark
      ? `
      background: ${props.theme.color.background[15]};
      background: linear-gradient(
        20deg,
        ${props.theme.color.background[65]},
        ${props.theme.color.background[45]}
      );
    `
      : `
    background: ${props.theme.color.background[90]};
    background: linear-gradient(
      20deg,
      ${props.theme.color.background[95]},
      ${props.theme.color.background[85]}
    );
    `
  };
  display: flex;
  flex-direction: column;
  border-radius: ${props.theme.size.base}px;
  margin-bottom: ${props.theme.size.base * 4}px;
  .featured-thumbnail {
    border-radius:
     ${props.theme.size.base}px 0 
     0 ${props.theme.size.base}px;
   overflow: hidden;
 }
  .summary {
    padding: ${props.theme.size.base * 4}px;
  }
  .date {
    text-transform: uppercase;
    font-weight: 400;
    letter-spacing: 0.1em;
    font-size: ${props.theme.size.fontSize[2]};
    line-height: ${props.theme.size.lineHeight[2]};
    margin-bottom:  ${props.theme.size.base * 1.5}px;
    color: ${
      props.theme.dark
        ? props.theme.color.secondary[60]
        : props.theme.color.secondary[40]
    };
  }
  .tags {
    margin-bottom:  ${props.theme.size.base}px;
  }
  .title {
    display: block;
    font-weight: 700;
    font-size: ${props.theme.size.fontSize[6]};
    line-height: ${props.theme.size.lineHeight[6]};
    text-decoration: none;
    color: ${
      props.theme.dark
        ? props.theme.color.primary[60]
        : props.theme.color.primary[50]
    };
    &:hover {
      color: ${
        props.theme.dark
          ? props.theme.color.secondary[70]
          : props.theme.color.secondary[60]
      };
    }
    transition: color 450ms ease;
    h2 {
      margin: 0;
    }
    &:hover {
      color: ${tinycolor
        .mostReadable(props.theme.color.background[65], [
          props.theme.color.primary[45],
          props.theme.color.primary[75],
        ])
        .toHexString()};
    }
  }
  .excerpt {
    font-size: ${props.theme.size.fontSize[2]};
    line-height: ${props.theme.size.lineHeight[2]};
    color: ${props.theme.color.foreground[50]};
    margin-top:  ${props.theme.size.base * 2}px;
  }
  .continue-reading {
    margin-top:  ${props.theme.size.base * 2}px;
  }
  ${
    (props.breakpoints.lg || props.breakpoints.xl) &&
    `
    flex-direction: row;
    .featured-thumbnail {
      flex: 0 0 40%;
      * {
        object-fit: cover;
        height: 100%;
      }
    }
    .summary {
      flex: 0 0 calc(60% - ${props.theme.size.base * 16}); 
      padding: ${props.theme.size.base * 8}px;
    }
  `
  };
`
);
