import React from 'react';
import styled from '@emotion/styled';
import { Helmet } from 'react-helmet';
import { Link, graphql } from 'gatsby';
import { navigate } from 'gatsby-link';
import PreviewCompatibleImage from '../components/PreviewCompatibleImage';
import RouterTracker from '../components/RouterTracker';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

import Button from '../components/Button';
import ButtonOutline from '../components/ButtonOutline';

import ThemeContext from '../context/ThemeContext';
import { PostDetails } from './PostDetails';
import SvgArrowRight from '../icons/ArrowRight';

const AuthorStyles = styled.div(
  (props) => `
  padding: ${props.theme.size.base * 13}px 
    ${props.theme.size.base * 4}px 
    ${props.theme.size.base * 4}px;
  ${
    (props.breakpoints.lg || props.breakpoints.xl) &&
    `
   padding: ${props.theme.size.base * 8}px;
  `
  };
  
  .body {
    position: relative;
    z-index: 500;
    max-width: ${props.theme.size.base * 128}px;
    margin: 0 auto;
  }
`
);
const AuthorRoute = ({ data }) => {
  const breakpoints = useBreakpoint();
  const posts = data.allMarkdownRemark.edges;
  const postLinks = posts.map((post) => (
    <ThemeContext.Consumer key={post.node.fields.slug}>
      {(theme) => (
        <PostDetails theme={theme} breakpoints={breakpoints}>
          {post.node.frontmatter.featuredimage ? (
            <div className='featured-thumbnail'>
              <Link to={post.node.fields.slug}>
                <PreviewCompatibleImage
                  imageInfo={{
                    image: post.node.frontmatter.featuredimage,
                    alt: `featured image thumbnail for post ${post.node.frontmatter.title}`,
                  }}
                />
              </Link>
            </div>
          ) : null}
          <div className='summary'>
            <div className='post-meta'>
              <div className='date'>{post.node.frontmatter.date}</div>
              <Link className='title' to={post.node.fields.slug}>
                <h2>{post.node.frontmatter.title}</h2>
              </Link>
              <div className='excerpt'>{post.node.excerpt}</div>
              <div className='continue-reading'>
                <ButtonOutline
                  onClick={() => {
                    navigate(post.node.fields.slug);
                  }}
                  size={-1}
                  shape={4}
                  color='tertiary'
                >
                  Continue Reading{' '}
                  <SvgArrowRight
                    style={{ marginLeft: theme.size.base * 1 }}
                  ></SvgArrowRight>
                </ButtonOutline>
              </div>
            </div>
          </div>
        </PostDetails>
      )}
    </ThemeContext.Consumer>
  ));
  const author = data.author.nodes[0].frontmatter.name;
  const title = data.site.siteMetadata.title;
  const totalCount = data.allMarkdownRemark.totalCount;
  const authorHeader = `${totalCount} post${
    totalCount === 1 ? '' : 's'
  } authored by “${author}”`;

  return (
    <ThemeContext.Consumer>
      {(theme) => (
        <AuthorStyles theme={theme} breakpoints={breakpoints}>
          <RouterTracker />
          <Helmet title={`${author} | ${title}`} />
          <div className='body'>
            <h3 className='title'>{authorHeader}</h3>
            <div className='authorlist'>{postLinks}</div>
            <p>
              <Button
                onClick={() => {
                  navigate('/authors/');
                }}
                color='tertiary'
              >
                Browse all authors
              </Button>
            </p>
          </div>
        </AuthorStyles>
      )}
    </ThemeContext.Consumer>
  );
};

export default AuthorRoute;

export const authorPageQuery = graphql`
  query AuthorPage($author: String) {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      limit: 1000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { author: { eq: $author } } }
    ) {
      totalCount
      edges {
        node {
          excerpt(pruneLength: 128)
          fields {
            slug
          }
          frontmatter {
            title
            tags
            date(formatString: "MMMM DD, YYYY")
            featuredimage {
              childImageSharp {
                gatsbyImageData(width: 800, quality: 60, layout: CONSTRAINED)
              }
            }
          }
        }
      }
    }
    author: allMarkdownRemark(
      filter: {
        frontmatter: { templateKey: { eq: "author" }, name: { eq: $author } }
      }
    ) {
      nodes {
        frontmatter {
          name
          job_title
        }
      }
    }
  }
`;
